/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { rhythm } from "../utils/typography"

const Bio = ({ coAuthors, coAuthorLinks }) => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author
          social {
            twitter
          }
        }
      }
    }
  `)

  const { author, social } = data.site.siteMetadata
  return (
    <div
      style={{
        display: `flex`,
        marginBottom: rhythm(2.5),
      }}
    >
      <StaticImage className="bio-avatar" src={"../images/profile-pic_small.jpg"} alt={author} />
      <p>
        Written by <strong>{author}</strong>
        {coAuthors?.length ? (
          <span>
            {" "}
            and{" "}
            {coAuthors.map((author, index) => (
              <span key={author + index} style={{ marginRight: "1rem" }}>
                <a href={coAuthorLinks[index]}>
                  <em>{author}</em>
                </a>
                {index !== coAuthors.length - 1 ? "," : ""}
              </span>
            ))}
          </span>
        ) : (
          "."
        )}
        <br />I will make you believe that I'm secretly three senior engineers in a trench-coat. I overcome complex
        issues with ease and complete tasks at an impressive speed. I consistently guide teams to achieve their
        milestones and goals. I have a comprehensive skill set spanning from requirements gathering to front-end,
        back-end, pipelines, and deployments. I do my best to continuously grow and increase my capabilities.
        <br />
        <br />
        {` `}
        You can follow me on{` `}
        <a href={`https://twitter.com/${social.twitter}`}>Twitter</a> or
        {` `}
        <a href={`https://github.com/${social.twitter}`}>Github</a>.
      </p>
    </div>
  )
}

export default Bio
