import React from "react"
import { Link, graphql } from "gatsby"
import "../styles/global.css"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { rhythm } from "../utils/typography"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    const canonical = (url) => (
      <em>
        {" "}
        | Originally published on the <a href={url}>ThisDot blog</a>
      </em>
    )

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Seo title="All posts" />
        <Bio />
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          const coAuthors = (node.frontmatter.coAuthors || "").split(",").filter(Boolean)
          const coAuthorLinks = (node.frontmatter.coAuthorLinks || "").split(",").filter(Boolean)
          const canonicalUrl = node.frontmatter.canonicalUrl || null
          return (
            <article key={node.fields.slug}>
              <header>
                <h3
                  style={{
                    marginBottom: rhythm(1 / 4),
                  }}
                >
                  <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                    {title}
                  </Link>
                </h3>
                {coAuthors.length ? (
                  <h4 style={{ marginTop: "1rem", marginBottom: "0.5rem" }}>
                    Co-author{coAuthors.length > 1 ? "s" : ""}:{" "}
                    {coAuthors.map((author, index) => (
                      <a style={{ marginRight: "1rem" }} key={author + index} href={coAuthorLinks[index]}>
                        <em>{author}</em>
                      </a>
                    ))}
                  </h4>
                ) : null}
                <small>
                  {node.frontmatter.date} {canonicalUrl ? canonical(canonicalUrl) : ""}
                </small>
              </header>
              <section>
                <p
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt,
                  }}
                />
              </section>
            </article>
          )
        })}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            canonicalUrl
            coAuthors
            coAuthorLinks
          }
        }
      }
    }
  }
`
